import React, { useContext, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import RawHtml from 'utils/miscUtils/rawHtml';
import { scrubContent } from 'utils/stringUtils';
import { Article, ArticlePreview } from 'interfaces/content/articles/Articles';
import PresentedBy, { PresentedByProps } from 'components/PresentedBy';
import AdminBar from 'components/AdminBar';
import { UserPaywallContext } from 'providers/PaywallProvider';
import { Microsites, RedVentureWidgets, subdomainColors } from 'constants/constants';
import { UserContext } from 'providers/UserProvider';
import { EventData } from 'services/Gtm/entities';
import Hero from 'components/ArticleV2/HeroV2';
import getArticlePageTestId from 'constants/testsIds/articlePage';
import { PageType } from 'interfaces/content/articles/Post';
import dynamic from 'next/dynamic';
import ErrorBoundary from 'components/ErrorBoundary';
import { UIFragments } from 'utils/log/constants/uiFragments';
import getAdPlacementConfig, { AdPlacementConfigProps } from 'services/Ad/config/adPlacementConfig';
import * as S from './ArticleStyles';

const Breadcrumbs = dynamic(() => import('components/Breadcrumbs'));

const TrinityPlayer = dynamic(() => import('components/TrinityPlayer'), {
  ssr: false,
});

const LatestPopularContent = dynamic(() => import('components/ArticleV2/Latest'), {
  ssr: false,
});

const InStream = dynamic(() => import('components/Ad/AdSlot/InStream'), {
  ssr: false,
});

const ContentWithRVWidgets = dynamic(() => import('components/ContentWithRVWidgets'), {
  ssr: false,
});

const ContentWithEmbed = dynamic(() => import('components/ContentWithEmbed'), {
  ssr: false,
});

export interface ArticleProps {
  article: Article;
  presentedBy?: PresentedByProps;
  footer?: JSX.Element[];
  adFrequency?: number;
  embeds: { node: string; index: number; component: JSX.Element }[];
  lastPWithoutAds?: number;
  subdomain: string;
  microsite?: string;
  stickyTop?: number | null;
  zipCode?: string;
  latestPosts: {
    posts: ArticlePreview[];
  };
  mostPopular: {
    posts: ArticlePreview[];
  };
  noRecirculationModules?: boolean;
  noTrinityAds: boolean;
}

const ArticlePage: React.FC<ArticleProps> = ({
  article,
  presentedBy,
  footer,
  adFrequency,
  embeds,
  lastPWithoutAds,
  subdomain,
  microsite,
  stickyTop = null,
  zipCode,
  latestPosts,
  mostPopular,
  noRecirculationModules,
  noTrinityAds,
}) => {
  const { breadcrumbs, content, databaseId, sectionNames } = article;

  const hideLatestPopularModule = noRecirculationModules || false;
  const [adFrequencyViewPort, setAdFrequencyViewPort] = useState(adFrequency || 0);
  const [renderArticle, setRenderArticle] = useState(false);
  const adPlacementInfo: AdPlacementConfigProps = getAdPlacementConfig(sectionNames);

  const cleanedContent = useMemo(() => scrubContent(content), [content]);
  const widgetsInArticle = useMemo(
    () => Object.values(RedVentureWidgets).filter((id) => content.indexOf(id) > -1),
    [content],
  );
  const getViewportWidth = useCallback(() => window.innerWidth, []);
  useEffect(() => {
    const initialViewportWidth = getViewportWidth();
    if (!adFrequency) {
      if (initialViewportWidth < 768) setAdFrequencyViewPort(adPlacementInfo.mobileAdFrequency);
      else if (initialViewportWidth >= 768) {
        setAdFrequencyViewPort(adPlacementInfo.desktopAdFrequency);
      }
    }
    setRenderArticle(true);
  }, [adFrequency]);

  const { paywallCheck, regwallCheck } = useContext(UserPaywallContext).paywallState;
  const { userState: user } = useContext(UserContext);
  const [contentRendered, setContentRendered] = useState(false);

  const RawHtmlClass = paywallCheck === 'no-paywall' ? 'rawHtml-content' : 'rawHtml-content-no-nativo';

  const primarySection = {
    link: article.primarySection.uri,
    name: article.primarySection.name,
  };

  const breadcrumbsData = <Breadcrumbs {...breadcrumbs} />;

  const hasFeaturedMediaTypeVideo =
    article.featuredMediaType === 'fortune_video' || article.featuredMediaType === 'stn_video_media';

  const trackingData: EventData = {
    HasVideoCD: hasFeaturedMediaTypeVideo,
    articleNameCD: article.title,
    authorNameCD: article.authorNames,
    cmsCategoryCD: 'article-v2',
    cmsSubCategoryCD: article.primarySection.name,
    contentCMSID: article.postId,
    contentPublishedDateCD: article.dateGmt,
    contentTagsCD: article.tagNames,
    hitIDCD: '',
    loginStatusCD: user.isLoggedIn ? 'logged-in' : 'guest',
    platformCD: article.platformCD || 'own',
    premiumCategoryCD: article?.premiumCategory.name || '',
    sessionIDCD: '',
    userAgentCD: typeof window !== 'undefined' ? window.navigator?.userAgent : '',
  };

  const widgetsTrackingData: EventData = {
    ...trackingData,
    contentPlacementCD: 'Recommends Widget',
  };

  const latestModuleTrackingData: EventData = {
    ...trackingData,
    contentPlacementCD: 'latest - bottom',
    eventAction: 'latest module click',
    eventCategory: 'navigation',
  };

  let latestTitle = '';
  if (microsite === Microsites.Crypto) {
    latestTitle = 'Latest in Crypto';
  } else if (primarySection.name === 'Magazine') {
    latestTitle = `Latest from the ${primarySection.name}`;
  } else if (primarySection.name === 'Conferences') {
    latestTitle = `Latest from our ${primarySection.name}`;
  } else {
    latestTitle = `Latest in ${primarySection.name}`;
  }

  const trinityRef = useRef<HTMLDivElement>(null);

  return (
    <S.Container
      id='content'
      role='article'
    >
      {presentedBy && <PresentedBy {...presentedBy} />}
      <ErrorBoundary fragment={UIFragments.ARTICLE_PAGE}>
        {Microsites.Recommends === microsite && <S.BreadcrumbsContainer> {breadcrumbsData} </S.BreadcrumbsContainer>}
      </ErrorBoundary>

      <AdminBar pageId={databaseId} />
      <ErrorBoundary fragment={UIFragments.ARTICLE_PAGE}>
        <Hero article={article} />
      </ErrorBoundary>

      <ErrorBoundary fragment={UIFragments.ARTICLE_PAGE}>
        <S.TrinityPlayerContainer ref={trinityRef}>
          <TrinityPlayer
            url={article.url}
            noTrinityAds={noTrinityAds}
            paywallCheck={paywallCheck}
            regwallCheck={regwallCheck}
            trinityRef={trinityRef}
            microsite={subdomain}
          />
        </S.TrinityPlayerContainer>
      </ErrorBoundary>

      <S.ArticleContentContainer>
        <S.ArticleImageAndContentContainer>
          <S.ArticleContentWrapper
            id='article-content'
            $subdomain={subdomain}
            $stickyTop={stickyTop}
            $color={subdomainColors[subdomain]}
          >
            {renderArticle && (
              <ErrorBoundary fragment={UIFragments.ARTICLE_PAGE}>
                <ContentWithRVWidgets
                  widgetIds={widgetsInArticle}
                  microsite={subdomain}
                  zipCode={zipCode}
                  trackingData={widgetsTrackingData}
                  postId={article.postId}
                >
                  <ContentWithEmbed
                    embedInstructions={embeds.map((embed) => ({ index: embed.index, node: embed.node }))}
                    adFrequency={adFrequencyViewPort}
                    lastPWithoutAds={lastPWithoutAds}
                    pageType={PageType.ARTICLE_V2}
                    setContentRendered={setContentRendered}
                  >
                    {[
                      ...embeds.map((embed) => embed.component),
                      <div
                        key={`article-content-${adFrequencyViewPort}`}
                        data-cy={getArticlePageTestId('CONTENT')}
                        className={RawHtmlClass}
                      >
                        {RawHtml({ html: cleanedContent })}
                      </div>,
                    ]}
                  </ContentWithEmbed>
                </ContentWithRVWidgets>
              </ErrorBoundary>
            )}
            {adPlacementInfo && contentRendered && adPlacementInfo.showEndArticleAd && (
              <InStream
                index={100}
                delay={0}
                renderAds
              />
            )}
            <ErrorBoundary fragment={UIFragments.ARTICLE_PAGE}>
              {primarySection.name !== 'Commentary' && footer && <S.FooterStyles>{footer}</S.FooterStyles>}
            </ErrorBoundary>
          </S.ArticleContentWrapper>
          {!hideLatestPopularModule && contentRendered && (
            <>
              <ErrorBoundary fragment={UIFragments.ARTICLE_PAGE}>
                {latestPosts && (
                  <LatestPopularContent
                    content={latestPosts}
                    trackingData={latestModuleTrackingData}
                    title={latestTitle}
                  />
                )}
              </ErrorBoundary>
              {adPlacementInfo.showBetweenLatestsAndPopular && (
                <InStream
                  index={101}
                  delay={0}
                  renderAds
                />
              )}
              <ErrorBoundary fragment={UIFragments.ARTICLE_PAGE}>
                {mostPopular && (
                  <LatestPopularContent
                    content={mostPopular}
                    trackingData={latestModuleTrackingData}
                    title='Most Popular'
                  />
                )}
              </ErrorBoundary>
              {mostPopular.posts.length > 0 && adPlacementInfo.showBetweenLatestsAndPopular && (
                <InStream
                  index={102}
                  delay={0}
                  renderAds
                />
              )}
            </>
          )}
        </S.ArticleImageAndContentContainer>
      </S.ArticleContentContainer>
    </S.Container>
  );
};

export { ArticlePage };
