import dynamic from 'next/dynamic';
import { NextCustomImage } from 'components/Image';
import { PostSettings } from 'interfaces/content/articles/PostSettings';
import { Caption, Credit } from 'pages/Article/V1/ArticleStyles';
import RawHtml from 'utils/miscUtils/rawHtml';
import getArticlePageTestId from 'constants/testsIds/articlePage';
import { withErrorBoundary } from 'components/ErrorBoundary';
import { UIFragments } from 'utils/log/constants/uiFragments';
import * as S from './FeaturedHeroMediaStyles';

const StnVideo = dynamic(() => import('components/StnVideo'), {
  ssr: false,
});
const VodVideo = dynamic(() => import('components/VodVideo'), {
  ssr: false,
});

interface FeaturedMediaProps {
  featuredMediaType: string;
  imageCaption: string;
  imageCredit: string;
  postSettings: PostSettings;
  videoId: string | null;
  className?: string;
}

const FeaturedHeroMedia = ({
  featuredMediaType,
  imageCaption,
  imageCredit,
  postSettings,
  videoId,
  className,
}: FeaturedMediaProps) => {
  const {
    mediaItem: { altText, mediaItemUrl },
  } = postSettings;

  switch (featuredMediaType) {
    case 'image':
      return (
        <>
          <NextCustomImage
            alt={altText !== '' ? altText : imageCaption}
            priority
            src={mediaItemUrl}
            width='768'
            height='512'
          />
          <Caption data-cy={getArticlePageTestId('CAPTION')}>{RawHtml({ html: imageCaption })}</Caption>
          <Credit data-cy={getArticlePageTestId('CREDIT')}>{RawHtml({ html: imageCredit })}</Credit>
        </>
      );
    case 'fortune_video':
      return videoId ? (
        <VodVideo
          className={className}
          videoId={videoId}
        />
      ) : null;
    case 'stn_video_media':
      return videoId ? (
        <S.VideoContainer>
          <StnVideo videoId={videoId} />
        </S.VideoContainer>
      ) : null;
    default:
      return null;
  }
};

export default withErrorBoundary(FeaturedHeroMedia, { fragment: UIFragments.ARTICLE_MEDIA });
